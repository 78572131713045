import React, { Component, useEffect, useRef, useState } from "react";
import axiosInstance from "../../../axiosApi";
import { Chart } from "chart.js";
import moment from "moment";

export default function WeeklyRetentionTotalsByAgentChart(props) {
  const chartRef = useRef();
  let myChart;
  let data;
  let myChartRef;
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState({});
  const [chartType, setChartType] = useState(props.type);
  const [chartOptions, setChartOptions] = useState({
    response: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      title: {
        display: true,
        text: "Weekly Retention Resolves",
      },
    },
    scales: {
      y: {
        min: 0,
        ticks: {
          stepSize: 25,
        },
      },
    },
  });

  let backgroundColors = {
    DominiqueOverstreet: {
      backgroundColor: "rgb(54, 162, 235, 0.6)",
      borderColor: "rgba(54, 162, 235)",
    },
    tylerbutler: {
      backgroundColor: "rgb(54, 162, 235, 0.6)",
      borderColor: "rgba(54, 162, 235)",
    },
    KaylaBurget: {
      backgroundColor: "rgb(255, 150, 0, 0.6)",
      borderColor: "rgb(255, 150, 0)",
    },
    NatashaSmithJones: {
      backgroundColor: "rgb(100, 100, 250, 0.6)",
      borderColor: "rgb(100, 100, 250)",
    },
    AdrianaPantoja: {
      backgroundColor: "rgb(255, 150, 0, 0.6)",
      borderColor: "rgb(255, 150, 0)",
    },
    tylerbutler: {
      backgroundColor: "rgb(255, 150, 0, 0.6)",
      borderColor: "rgb(255, 150, 0)",
    },
    LatoyaWilliams: {
      backgroundColor: "rgb(150, 0, 0, 0.6)",
      borderColor: "rgb(150, 0, 0)",
    },
    CiarraPass: {
      backgroundColor: "rgb(0, 150, 0, 0.6)",
      borderColor: "rgb(0, 150, 0)",
    },
    gennawatson: {
      backgroundColor: "rgb(54, 162, 235, 0.6)",
      borderColor: "rgba(54, 162, 235)",
    },
  };

  useEffect(() => {
    axiosInstance
      .get("agentresolves/weekly/")
      .then(function (response) {
        let agents;
        let labels;

        labels = response.data.map(function (e) {
          return moment()
            .day("Monday")
            .week(e.week)
            .year(e.year)
            .format("MM/DD/YY");
        });

        agents = response.data.map(function (e) {
          return e.agent;
        });

        agents = [...new Set(agents)];
        labels = [...new Set(labels)];
        var datasets = [];
        var temp_var;

        [...new Set(agents)].map(function (e) {
          var dataloop = [];
          var temp_labels = [];
          response.data.filter(function (item) {
            temp_var = moment()
              .day("Monday")
              .week(item.week)
              .year(item.year)
              .format("MM/DD/YY");
            if (item.agent === e) {
              dataloop.push(item.total);
              temp_labels.push(temp_var);
            }
          });
          labels.map(function (d) {
            if (temp_labels.indexOf(d) === -1) {
              dataloop.splice(labels.indexOf(d), 0, null);
            }
          });

          datasets.push({
            label: e,
            data: dataloop,
            lineTension: 0.1,
            backgroundColor: backgroundColors[e]["backgroundColor"],
            borderColor: backgroundColors[e]["borderColor"],
            borderWidth: 3,
            borderRadius: 3,
          });
        });

        myChartRef = chartRef.current.getContext("2d");

        if (typeof myChart !== "undefined") myChart.destroy();

        data = {
          labels: [...new Set(labels)],
          datasets: datasets,
        };

        setChartData(data);

        myChart = new Chart(myChartRef, {
          type: "bar",
          data: data,
          options: chartOptions,
          plugins: [
            {
              beforeDraw: function (chart) {
                var ruleIndex = 0;
                var rules = chart.config._config.options.backgroundRules;
                var yaxis = chart.scales.y;
                var xaxis = chart.scales.x;
                var partPercentage = 1 / (yaxis.ticks.length - 1);
                yaxis.ticks.map((tick) => {
                  if (tick.value >= 275) {
                    myChartRef.fillStyle = "rgba(0,155,0,0.4)";
                    myChartRef.fillRect(
                      xaxis.left,
                      yaxis.top +
                        (yaxis.ticks.length - 1 - tick.$context.index) *
                          (yaxis.height * partPercentage),
                      xaxis.width,
                      yaxis.height * partPercentage
                    );
                  } else if (tick.value >= 225) {
                    myChartRef.fillStyle = "rgba(0,155,0,0.2)";
                    myChartRef.fillRect(
                      xaxis.left,
                      yaxis.top +
                        (yaxis.ticks.length - 1 - tick.$context.index) *
                          (yaxis.height * partPercentage),
                      xaxis.width,
                      yaxis.height * partPercentage
                    );
                  }
                });
              },
            },
          ],
        });
        setIsLoading(false);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, [setChartData]);

  return (
    <div>
      <canvas id="WeeklyRetentionTotalsByAgentChart" ref={chartRef} />
    </div>
  );
}
