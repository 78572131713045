import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink } from "react-router-dom";
import { Link } from "@mui/material";
import { useState, useEffect } from "react";
import axiosInstance from "../axiosApi";
import { useHistory } from "react-router";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import HomeIcon from "@mui/icons-material/Home";
import MapIcon from "@mui/icons-material/Map";
import PeopleIcon from "@mui/icons-material/People";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import TrainIcon from "@mui/icons-material/Train";
import BabyChangingStation from "@mui/icons-material/BabyChangingStation";
const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Header() {
  let history = useHistory();
  const [isLogged, setisLogged] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const userId = localStorage.getItem("user_id");
  const allowedUserIds = [
    "412", "223", "90", "93", "109", "111", "411", "7", "88", "222", 
    "284", "290", "195", "202", "196", "194", "279", "201", "219", "32",
    "423", "30", "344", "352", "413", "82", "427", "37", "42", "1169", "1301", "1334"
  ];
  const theme = useTheme();

  useEffect(() => {
    checkStorage();
    return () => {};
  }, [isLogged]);

  function checkStorage() {
    if (localStorage.getItem("user_id")) {
      setisLogged(true);
    } else {
      setisLogged(false);
    }
  }

  useEffect(() => {
    if (
      userId && allowedUserIds.includes(userId)
    ) {
      setShowMenu(true);
    }
    return () => {};
  }, [setShowMenu]);

  function handleLogout(event) {
    event.preventDefault();
    axiosInstance
      .post("/user/logout/blacklist/", {
        refresh_token: localStorage.getItem("refresh_token"),
      })
      .then((result) => {
        axiosInstance.defaults.headers["Authorization"] = null;
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("username");
        localStorage.removeItem("user_id");
        localStorage.removeItem("user_status");
        history.push("/");
      })
      .catch((error) => {
        throw error;
      });
  }
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          {showMenu ? (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerOpen}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <div></div>
          )}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link component={NavLink} to="/" underline="none" color="#fff">
              CAMP
            </Link>
          </Typography>
          {isLogged ? (
            <Button
              color="primary"
              variant="contained"
              component={NavLink}
              to="/logout"
            >
              Logout
            </Button>
          ) : (
            <Button
              color="primary"
              variant="contained"
              component={NavLink}
              to="/login"
            >
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
      >
        <Divider />
        <List>
          <ListItem
            button
            component={NavLink}
            to="/"
            onClick={handleDrawerClose}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem
            button
            component={NavLink}
            to="/hierarchy"
            onClick={handleDrawerClose}
          >
            <ListItemIcon>
              <AccountTreeIcon />
            </ListItemIcon>
            <ListItemText primary="Hierarchy" />
          </ListItem>
          <ListItem
            button
            component={NavLink}
            to="/territories"
            onClick={handleDrawerClose}
          >
            <ListItemIcon>
              <MapIcon />
            </ListItemIcon>
            <ListItemText primary="Territories" />
          </ListItem>
          <ListItem
            button
            component={NavLink}
            to="/counties"
            onClick={handleDrawerClose}
          >
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Counties" />
          </ListItem>
          {/* <ListItem
            button
            component={NavLink}
            to="/recruiting"
            onClick={handleDrawerClose}
          >
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Recruiting" />
          </ListItem> */}
          <ListItem
            button
            component={NavLink}
            to="/retention"
            onClick={handleDrawerClose}
          >
            <ListItemIcon>
              <AttachMoneyIcon />
            </ListItemIcon>
            <ListItemText primary="Retention" />
          </ListItem>
          <ListItem
            button
            component={NavLink}
            to="/onboardingadmin"
            onClick={handleDrawerClose}
          >
            <ListItemIcon>
              <BabyChangingStation />
            </ListItemIcon>
            <ListItemText primary="Onboarding" />
          </ListItem>
          <ListItem
            button
            component={NavLink}
            to="/trainingadmin"
            onClick={handleDrawerClose}
          >
            <ListItemIcon>
              <TrainIcon />
            </ListItemIcon>
            <ListItemText primary="Training" />
          </ListItem>
        </List>
        <Divider />
      </Drawer>
    </Box>
  );
}
