export const backgroundColors = {
  DominiqueOverstreet: {
    backgroundColor: "rgb(54, 162, 235, 0.6)",
    borderColor: "rgba(54, 162, 235)",
  },
  JacobeeSatterfield: {
    backgroundColor: "rgb(255, 150, 0, 0.6)",
    borderColor: "rgb(255, 150, 0)",
  },
  LatoyaWilliams: {
    backgroundColor: "rgb(150, 0, 0, 0.6)",
    borderColor: "rgb(150, 0, 0)",
  },
  CiarraPass: {
    backgroundColor: "rgb(0, 150, 0, 0.6)",
    borderColor: "rgb(0, 150, 0)",
  },
  tylerbutler: {
    backgroundColor: "rgb(54, 162, 235, 0.6)",
    borderColor: "rgba(54, 162, 235)",
  },
  gennawatson: {
    backgroundColor: "rgb(54, 162, 235, 0.6)",
    borderColor: "rgba(54, 162, 235)",
  },
};
